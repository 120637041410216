@import "../../styles/common/colors";

.panel {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 250px;
  height: 241px;
  border: 2px solid $smoke;
  border-radius: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px 35px;

  &.active,
  &:hover {
    border-color: $sky-blue;
    -webkit-box-shadow: 0 24px 28px 0 rgba(45, 50, 51, 0.08);
            box-shadow: 0 24px 28px 0 rgba(45, 50, 51, 0.08);
  }
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.text {
  width: 210px;
  font-size: 14px;
  color: $gray;
  text-align: center;
  word-wrap: break-word;
}

.panel-title {
  margin: 0px 0px 20px;
}

.image {
  height: 110px;
  width: 110px;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #f6f6f6;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -20px 0px 30px;
}