.content {
  width: 50%;
  max-width: 540px;
}

.message {
  textarea {
    height: 150px;
  }
  margin-bottom: 40px;
}

.input {
  width: 100%;
  margin-top: 40px;

  &:first-child {
    margin: 10px 0px 20px;
  }
}