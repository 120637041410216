@import '../../../styles/common/colors';

.item {
  text-align: left;
  padding: 8px 24px 8px 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: $dark-gray;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: $snow;
    fill: $sky-blue;
  }

  &:focus {
    background-color: $snow;
    border-left: 4px solid $sky-blue;
    fill: $sky-blue;
  }

  &.selected {
    background-color: $lightest-sky-blue-two;
  }

  &.base {
    color: $dark-gray;
    fill: $dark-gray;
  }

  &.danger {
    color: $dark-red;
    fill: $dark-red;

    &:hover {
      background-color: rgba($dark-red, 0.1);
    }

    &:focus {
      background-color: rgba($dark-red, 0.1);
      border-left: 4px solid $dark-red;
    }
  }
}
