@import '../../../styles/common/colors';

.container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  .menu {
    position: absolute;
    right: 0;
    top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 278px;
    padding: 4px 0;
    border-radius: 4px;
    background-color: $white;
    -webkit-box-shadow: 0 0 0 1px #ececec, 0 2px 16px 0 rgba(45, 50, 51, 0.1);
            box-shadow: 0 0 0 1px #ececec, 0 2px 16px 0 rgba(45, 50, 51, 0.1);
  }
}
