@import "../../../styles/common/colors";
@import "../../../styles/common/text";

.input-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;

  @extend %paragraph;

  .labels-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;

    .label {
      @extend %label;
      text-align: left;
      color: $gray;
      margin-bottom: 10px;
    }

    .error {
      margin-left: auto;
      margin-bottom: 10px; /* it's important to have margin for status and for label, not for parent container */
    }
  }
}